import React from "react";

const TermsAndConditions = props => {
  document.title = `Terms of Service - Technology Capability Repository || DSCI`;
  return (
    <>
      {/* Banner Section */}
      <div className="infoPage__banner infoPage__banner--about">
        <p className="infoPage__bannerTitle">Terms Of Service</p>
      </div>
      {/* End of banner section */}

      {/* Main section */}
      <main className="infoPage__body">
        <div className="infoPage__bodyBox">
          <p className="infoPage__bodyText">
            The Terms of Service govern the use of this TechSagar Portal and the content contained therein. All Users/Organizations/Visitors of the Portal who may or may not be registered on the portal are expected to adhere to the Terms of Service of the said Portal. When any user uses this portal, then they acknowledge to have read the Terms of Service and accept that they will be bound by the terms hereof and such terms as may be modified from time to time.<br /><br />

            Any information and content featured on this portal may not be distributed, modified, reproduced, or used, in whole or in part, without the prior written consent of DSCI; except that you may download the content or test from this system to any single computer, provided, you keep intact all copyright, trademark, and other proprietary notices; and wherever the information is being published, reproduced, or issued to others, the source must be prominently acknowledged. The information on the portal should be reproduced accurately and must not be used in a derogatory manner or in a misleading context.<br /><br />

            TechSagar Portal trademarks and copyrights may not be used in connection with any product or service that is not related to the portal, in any manner that is likely to confuse customers, or in any way that disparages or discredits TechSagar portal. All other trademarks and logos used in this system are the trademarks or logos of their respective owners. Users gain no right to use any representations of TechSagar Portal or DSCI or any other entity by virtue of the use of this system.<br /><br />

            Unless otherwise indicated in this system, including the Privacy Policy or in connection with one of the services, any communications or material of any kind that is transmitted through this portal (excluding personally identifiable information of individuals) including, reports, and other statistical information, will be treated as non-confidential and non-proprietary. The use of personally identifiable information of an individual is outlined in the Privacy Policy.<br /><br />

            DSCI reserves the right to modify, suspend/cancel, or discontinue any or all sections, or service at any time without notice. It also reserves the right to make modifications and alterations in the information contained on the portal without notice.<br /><br />

            DSCI reserves the right to discontinue the member login on account of non-payment of subscription fee or violation of the ‘code of conduct’ set by itself.<br /><br />

            DSCI reserves the right to deny, at its sole discretion, any user whatsoever access to the portal or a part thereof.
            <br /><br />

            DSCI reserves the right to modify the terms of use of any service without any liability. DSCI reserves the right to take all measures necessary to prevent access to any service or termination of service if the terms of use are not complied with or are contravened or there is any violation of copyright, trademark, or other proprietary rights.<br /><br />

            Notwithstanding Our efforts to ensure that the state portal system is secure, you acknowledge that all electronic data transfers are potentially susceptible to interception by others.<br /><br />

            <strong style={{ fontSize: '1.8rem' }}>AI-Powered Search - Terms of Use</strong>
            <br /><br />
            <strong >1. Introduction</strong>
            <br />
            Welcome to the TechSagar AI-Powered Search feature. By accessing or using this feature, you agree to abide by the following Terms of Use. These terms govern your use of the TechSagar AI-powered Search, which incorporates a Retrieval-Augmented Generation (RAG) Generative AI model and forms a legally binding agreement between you and TechSagar.
            <br /><br />

            <strong >2. Acceptance of Terms</strong>
            <br />
            By using the TechSagar AI-Powered Search, you agree to comply with and be bound by these Terms of Use. If you do not agree with these terms, you must not use this feature.
            <br /><br />

            <strong >3. Description of Service</strong>
            <br />
            The TechSagar AI-powered search feature provides users with advanced search capabilities driven by a Retrieval-Augmented Generation (RAG) Generative AI model. This technology combines knowledge retrieval with generative capabilities to deliver context-aware responses based on your queries, drawing on a broad range of data sources. While we strive for accuracy, AI technology may occasionally make mistakes. We recommend that you double-check any critical information before using it.
            <br /><br />

            <strong >4. User Obligations</strong>
            <br />
            •	Users must use the TechSagar AI-powered search feature for lawful and authorized purposes only.<br />
            •	Users agree not to use the feature to disseminate, share, or store illegal content, misleading information, or materials that infringe on intellectual property rights.<br />
            •	Users are responsible for ensuring that their use of the feature complies with all applicable laws and regulations.<br /><br />

            <strong >5. Intellectual Property</strong>
            <br />
            All content, data, and information provided by the TechSagar AI-Powered Search feature are the intellectual property of TechSagar or its licensors. Users may not reproduce, distribute, or use the information obtained through this feature for commercial purposes without explicit permission from TechSagar.
            <br /><br />

            <strong >6. Data Privacy and Security</strong>
            <br />
            TechSagar is committed to protecting your privacy and data. The use of this feature is subject to the TechSagar Privacy Policy. By using this feature, you consent to the collection and processing of your data in accordance with the Privacy Policy.
            <br /><br />

            <strong >7. Limitation of Liability</strong>
            <br />
            •	TechSagar makes no guarantees regarding the accuracy, reliability, or completeness of the information generated by the RAG Generative AI model.<br />
            •	Users acknowledge that the AI-generated responses are for informational purposes only and may not always be fully accurate or up-to-date.<br />
            •	TechSagar is not liable for any direct, indirect, incidental, or consequential damages resulting from the use of this feature.<br /><br />

            <strong >8. Prohibited Uses</strong>
            <br />
            Users must not:<br />
            •	Use the feature to infringe on the rights of others, including privacy, intellectual property, or contractual rights.<br />
            •	Engage in any activity that disrupts or interferes with the proper functioning of the feature.<br />
            •	Attempt to reverse-engineer, disassemble, or otherwise extract the source code of the RAG Generative AI model.<br /><br />

            <strong >9. Modifications to Terms and Feature</strong>
            <br />
            TechSagar reserves the right to modify these Terms of Use at any time. Users will be notified of any significant changes, and continued use of the feature constitutes acceptance of the updated terms. Additionally, TechSagar may update or discontinue the AI-powered search feature without notice or liability.
            <br /><br />

            <strong >10. Termination of Access</strong>
            <br />
            TechSagar may terminate or suspend access to the AI-powered search feature at its discretion, without prior notice, for violations of these terms or for other reasons, including maintenance or security purposes.
            <br /><br />

            <strong style={{ fontSize: '1.8rem' }}>Refund & cancellation policy</strong>
            <br /><br />

            Currently, the access to TechSagar is free of cost. Once you register, you will be able to access all the tabs at no cost. In the future when TechSagar begins paid knowledge offerings (documents, reports, and limited access to technology areas, etc.), refunds and cancellations will be subject to this policy. All the subscribers will have access to free resources at all times. There will be certain offerings that will be subject to subscription costs (clearly labeled). Once the subscriber downloads the document, the fee for the document or knowledge asset will not be refunded under any circumstances. We reserve the right to change the refund & cancellation policy at any time without prior notice. If any changes are made, the revised policy shall be posted on this website immediately.
          </p>
        </div>
      </main>
    </>)
};

export default TermsAndConditions;
