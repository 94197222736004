import React, { Component, Fragment, lazy } from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import '../css/inline-svgs.css';
import '../css/HomeNew.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import AreaCard from "./home-components/AreaCard";
import { Link } from "react-router-dom";
import { setUserInfo } from "../actions/auth";
import { setFormType } from "../actions/user-form";
import { updateArea, updateCapability } from "../actions/search";
import { getSearchPlaceholder } from '../utils/helpers';
import api, { getGETConfig, getAuthGETConfig } from "../api";
//import bannerLogo from '../img/banner-new/banner-logo.png';
//import sprite from '../img/sprite.svg';


import "../css/Home.css";
import { searchDropdownData, allAreas, features } from '../staticData';

import TechAreasDrawer from "./TechAreasDrawer";
import HeaderNew from "./HeaderNew";
import All from '../img/All.png'
import companies from '../img/companies.png'
import academia from '../img/academia.png'
import RDcenter from '../img/r-d-center.png'
import researchers from '../img/researchers.png'
import products from '../img/unique-products.png'
import { withRouter } from 'react-router-dom';
import right from '../img/right.png'

const items = [
    { imageUrl: All, itemName: 'All' },
    { imageUrl: companies, itemName: 'Companies' },
    { imageUrl: academia, itemName: 'Academia' },
    { imageUrl: RDcenter, itemName: 'R&D Centers' },
    { imageUrl: researchers, itemName: 'Researchers' },
    { imageUrl: products, itemName: 'Products' },
];
class HomeTop extends Component {
    state = {
        showTechAreasDrawer: false,
        selectedTechArea: '',
        showAllAreas: false,
        searchQuery: "",
        CarouselImages: [],
        showSuggestions: false,
        searchRedirect: false,
        resultType: "companies",
        suggestedEntities: [],
        suggestedLocations: [],
        suggestedTechAreas: [],
        suggestedProducts: [],
        eventData: [],
        eventState: '',
        signalNewsData: [],
        articleData: [],
        recentlyAddData: [],
        entitySummaryData: {},
        caseStudyData: [],
        contributorData: [],
        trendingData: [],
        contributorEmailId: "",
        liked: false,
        isloading: true,
        token: '',
        activeItem: 'All',
        inputPadding: { paddingLeft: '0px' },
        searchType: 'companies',
    };

    componentDidMount() {
        window.scrollTo(0, 0)
        this.calculateInitialPadding();
        const user = JSON.parse(localStorage.getItem("user"));
        if (user != null) {
            var token = user.token
        }
        this.setState({ token: token })


        document.addEventListener("click", this.handleClickOutside);


    }


    componentWillUnmount() {

        document.removeEventListener("click", this.handleClickOutside);
    }
    // Handles the click outside of the suggestions box to close it
    handleClickOutside = event => {
        let searchBox = document.getElementById("search-suggestions-box");
        if (searchBox && !searchBox.contains(event.target)) {
            this.hideSearchSuggestions();
        }
    };

    verifyEmail = () => {
        const requestConfig = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ token: this.props.match.params.token })
        };

        fetch(api.verifyEmail, requestConfig)
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(data => {
                if (!data.success) {
                    toast.error('Unable to verify email. Please try again!');
                    return;
                }
                toast.success("Thanks for verifying your email. Please login to continue.");
                localStorage.removeItem("user");
                this.props.setUserInfo(null);
                this.props.history.push("/");
                return;
            })
            .catch(err => console.log('Error verifying email', err));
    };
    followToggle = () => {
        //   console.log(this.state.contributorEmailId)
        if (!this.props.userInfo || !this.props.userInfo.username) {
            toast.error("You must login first to follow");
            return;
        }
        fetch(api.consumerActionOnContributor, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                TCRAUTH: this.props.userInfo.token,
            },
            body: JSON.stringify({
                email: this.state.contributorEmailId,
            }),
        }).then(response => {
            if (response.status !== 200) {
                console.log("Problem liking/following the tech area");
                return;
            }
            response.json().then(response => {
                if (response.success) {
                    this.setState(prevState => ({ liked: !prevState.liked }));
                }
            });
        });
    };






    getSuggestions = () => {
        // Do nothing if query is less than 3 letters
        if (this.state.searchQuery.replace(/\s+/, "").length < 2) return;
        // Query is 3 or more letters, fetch suggestions
        const { resultType } = this.state;
        if (resultType === 'tech_area') this.getTechAreaSuggestions();
        else if (resultType === 'products') this.getProductSuggestion();
        else this.getEntitySuggestions();
    }
    // Fetches the product suggestions
    getProductSuggestion = () => {
        const url = `${api.get_suggestion_product}?filter_type='products'&keyword=${this.state.searchQuery}`;
        fetch(url, getGETConfig())
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(({ data }) => {
                this.setState({ suggestedProducts: data.suggested_entities });
            })
            .catch(err => console.log('Error fetching product suggestions', err));
    };
    // Fetches the tech area suggestions
    getTechAreaSuggestions = () => {
        const url = `${api.tech_area_search}?keyword=${this.state.searchQuery}`;
        // console.log(this.state.searchQuery, "keyword")
        fetch(url, getGETConfig())
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(({ data }) => {
                this.setState({ suggestedTechAreas: data });
            })
            .catch(err => console.log('Error fetching tech area suggestions', err));
    };

    handleTechAreaSelect = (selectedTechArea) => {
        this.setState({ selectedTechArea });
    };
    // Fetches entity suggestions
    getEntitySuggestions = () => {

        let filterType = "";
        const { activeItem } = this.state;
        const { searchQuery, selectedTechArea } = this.state;
        if (activeItem === "All") {
            filterType = "";
        } else if (activeItem === "R&D Centers") {
            filterType = "r_and_d";
        } else {
            filterType = activeItem.toLowerCase();
        }
        const url = `${api.get_suggestions}?keyword=${this.state.searchQuery}&filter_type=${filterType}&tech_area=${selectedTechArea}`;
        fetch(url, getGETConfig())
            .then(response => {
                if (!response || !response.ok) throw Error(response.status);
                return response.json();
            })
            .then(({ data }) => {
                this.setState({
                    suggestedEntities: data.suggested_entities,
                    suggestedLocations: data.suggested_locations,
                    correctedWord: data.corrected_word
                });
            })
            .catch(err => console.log('Error fetching entity suggestions', err));
    };

    //  Expands or collapses the technology areas shown on home page (See Less/More)
    toggleShowAll = () =>
        this.setState(prevstate => ({
            showAllAreas: !prevstate.shFowAllAreas,
        }));
    //  Clear search query
    clearSearchQuery = () => this.setState({ searchQuery: "" });
    //  Handles query change, and calls the debounced get suggestions method
    onChangeQuery = event =>
        this.setState({ searchQuery: event.target.value }, this.dGetSuggestion);
    //  Show and Hide the search suggestions
    showSearchSuggestions = () => this.setState({ showSuggestions: true });
    hideSearchSuggestions = () => this.setState({ showSuggestions: false });

    //  Click handler when user clicks on a search suggestion
    suggestionClickedHandler = (entity, isLocation) => {
        const companyName = entity.name.replace("%", "percentage").replace(/ /g, "_")

        if (isLocation) return; //  Return if clicked entity was a location (no redirection needed)
        if (!entity.keyword_type) {
            if (entity.type === "Individual Researcher") {
                this.props.history.push(
                    {
                        pathname: `/researcher/${companyName}`,
                        state: `${entity.id}`
                    })
                return;
            }
            else if (entity.type === "R & D Centres") {
                this.props.history.push(
                    {
                        pathname: `/rd-centre/${companyName}`,
                        state: `${entity.id}`
                    })
                return;
            }
            else if (entity.type === "Academia") {
                this.props.history.push(
                    {
                        pathname: `/institute/${companyName}`,
                        state: `${entity.id}`
                    })
                return;
            }
            else {
                this.props.history.push(
                    {
                        pathname: `/company/${companyName}`,
                        state: `${entity.id}`
                    })
                return;
            }
        }
        //  For Technologies search
        let entityName = entity['name'].replace("%", "percentage").replace(/ /g, "_")
        let techAreaName = entity['name']
        if (entity.keyword_type === "tech_area") {

            this.props.history.push(
                {
                    pathname: `/${entityName}`,
                    state: { techAreaName }
                });
        } else {

            this.props.history.push({
                pathname: `/${entityName}/${entityName}`,
                state: { techAreaName }
            }
            );
        }
    };

    setResultType = type => {
        this.setState(
            {
                resultType: type,
                suggestedEntities: [],
                suggestedLocations: [],
                suggestedTechAreas: [],
                suggestedProducts: [],
            },
            this.dGetSuggestion
        );
    };

    sendSearchQuery = event => {
        event.preventDefault();
        if (this.state.searchQuery.trim().length) {
            this.props.updateArea("");
            this.props.updateCapability("");
            this.setState({
                searchRedirect: true,
            });
        }
    };
    capitalize = () => {
        var word = this.state.eventState
        return word[0].toUpperCase() + word.slice(1).toLowerCase();
    }

    //  Handles resultType change for mobile view dropdown
    handleDropdownChanged = option => this.setResultType(option.value);

    debounced = (delay, fn) => {
        let timerId;
        return function (...args) {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimeout(() => {
                fn(...args);
                timerId = null;
            }, delay);
        };
    };

    dGetSuggestion = this.debounced(400, this.getSuggestions);


    techIcon = (techarea) => {
        var techAreaIcon
        for (let i = 0; i < allAreas.length; i++) {
            if (allAreas[i].name === techarea) {
                techAreaIcon = allAreas[i].image
            }
        }
        return (
            <img src={techAreaIcon} alt='ai'
                className="home__previewRecentCard-top-imglogo" />
        )
    }
    toggleContributor = async (e) => {
        this.setState({
            contributorEmailId: e.target.id
        }, () => {
            this.followToggle()
        })
    }
    handleImageClick = (targetUrl) => {
        console.log("click")
        window.open(targetUrl, '_blank');
    };


    handleItemClick = (itemName) => {
        this.setState({ activeItem: itemName }, () => {
            this.getEntitySuggestions();
        });
        this.setState({ searchType: itemName == "Academia" || itemName == "Products" ? itemName.toLowerCase() : itemName == "All" || itemName == "Companies" ? "companies" : itemName == "Researchers" ? "individual" : "r_and_d" })

        if (itemName == "Academia" || itemName == "Products") {
            this.setState({ searchType: itemName.toLowerCase() })
        } else if (itemName == "All" || itemName == "Companies") {
            this.setState({ searchType: "companies" })
        } else if (itemName == "Researchers") {
            this.setState({ searchType: "individual" })
        } else {
            this.setState({ searchType: "r_and_d" })
        }

        // console.log(itemName)
    };

    //  Opens up the user form, calls the adjustBackgroundScrolling method as a callback for setState
    toggleUserForm = () => this.setState(prevState => ({
        showUserForm: !prevState.showUserForm
    }), this.adjustBackgroundScrolling);

    displayUserForm = () => {

        this.setState({
            showUserForm: true
        }, this.adjustBackgroundScrolling);
    }


    handleFeatureYourself = () => {
        // console.log("call handle")
        if (!this.props.userInfo) {
            toast.info("Please login to feature yourself!");
            this.props.setFormType('login');
            this.setState({ showUserForm: true });
        } else if (!this.props.userInfo.is_approved) {
            toast.error("Please verify your email id to feature yourself");
        } else {
            this.props.history.push('/feature_yourself');
        }
    };

    updateInputPadding = (newPadding) => {
        this.setState({ inputPadding: { paddingLeft: `${newPadding}px` } });
    };

    calculateInitialPadding = () => {
        const searchFilterSpan = document.querySelector('.searchFilter');
        const spanWidth = searchFilterSpan ? searchFilterSpan.offsetWidth + 26 : 0;
        this.setState({ inputPadding: { paddingLeft: `${spanWidth}px` } });
    };
    render() {

        document.title = "Home - Technology Capability Repository || DSCI";
        document.getElementsByTagName("LINK")[0].href = `https://www.techsagar.in`
        const responsive = {
            superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 3000 },
                items: 3
            },
            desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 2
            },
            tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2
            },
            mobile: {
                breakpoint: { max: 560, min: 0 },
                items: 1
            }
        };
        var recentlySlider = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            responsive: [
                {
                    breakpoint: 700,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        centerMode: false,
                        dots: false
                    }
                }
            ],
            // slidesToScroll={ 1}
            initialSlide: 0,
            // rows={ 2}
            slidesPerRow: 1,
            className: "center",
            centerMode: true
        }

        const placeholder = getSearchPlaceholder(this.state.resultType);
        let searchSuggestions = null;
        //  Product search suggestions
        if (this.state.resultType === "products") {
            searchSuggestions = this.state.suggestedProducts.map(
                (product, index) => (
                    <div
                        key={index}
                        className="home__search-suggestions-item"
                        onClick={this.suggestionClickedHandler.bind(this, product, false)}
                    >
                        {product.name}
                    </div>
                )
            );
        }
        //  Technologies search suggestions
        else if (this.state.resultType === "tech_area") {
            //  For Technologies
            searchSuggestions = this.state.suggestedTechAreas.map(
                (techarea, index) => (
                    <div
                        key={index}
                        className="home__search-suggestions-item"
                        onClick={this.suggestionClickedHandler.bind(
                            this,
                            techarea,
                            false
                        )}
                    >
                        {techarea.name}
                    </div>
                )
            );
        }
        //  Company, Academia, Researcher and RnD suggestions
        //  Also includes Location suggestions
        else {
            searchSuggestions = (
                <Fragment>
                    {this.state.suggestedEntities.map((entity, index) => (
                        <div
                            key={index}
                            className="home__search-suggestions-item"
                            onClick={this.suggestionClickedHandler.bind(this, entity, false)}
                        >{entity.name}</div>
                    ))}
                </Fragment>
            );
        }

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 2,
            // slidesToScroll: 1,
            initialSlide: 0,
            // rows: 2,
            slidesPerRow: 1,
            className: "center",
            centerMode: true,
        };


        var resultType = this.state.resultType
        if (this.state.searchQuery === "Academia" || this.state.searchQuery === "academia") {
            var resultType = "academia"
        }
        else if (this.state.searchQuery === "R&D Centers" || this.state.searchQuery === "r&d centers") {
            var resultType = "r_and_d"
        }
        else if (this.state.searchQuery === "Researchers" || this.state.searchQuery === "researchers") {
            var resultType = "individual"
        }
        else if (this.state.searchQuery === "Unique products" || this.state.searchQuery === "unique products") {
            var resultType = "products"
        }
        else {
            var resultType = this.state.resultType
        }

        const searchFilterSpan = document.querySelector('.searchFilter');
        const spanWidth = searchFilterSpan ? searchFilterSpan.offsetWidth + 26 : 0;

        // Calculate padding based on the span width
        const inputPadding = { paddingLeft: `${spanWidth}px` };


        const handleTechAreaSelect = () => {
            this.setState({ showSuggestions: false });
        };
        return (
            <>
                <div style={{
                    // backgroundImage: `url(${homePageImg})`,
                    backgroundImage: `url(https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/static-js/images/homepage-banner-v4.webp)`,
                    // backgroundImage: `url(https://tcr-phase2-documents.s3.ap-south-1.amazonaws.com/home-asset/HomePageNew.png)`,
                    backgroundRepeat: 'no-repeat',

                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundColor: 'rgba(255, 255, 255, -0.1)'
                }}
                    className="infoContainer">

                    <HeaderNew
                        handleFeatureYourself={this.handleFeatureYourself}
                        showUserForm={this.state.showUserForm}
                        toggleUserForm={this.toggleUserForm}
                    />
                    <div style={{ position: 'relative' }}>



                        <div className="containerHead">
                            {/* <h1>Repository of India's Cybertech Capabilities</h1>*/}
                            <h1> Repository of India's Emerging Tech Capabilities</h1>

                            {this.state.searchRedirect && (
                                <Redirect
                                    to={{
                                        pathname: "/search/",
                                        search: `?keyword=${["academia", "r&d centers", "researchers", "unique products", "companies"].includes(this.state.searchQuery) ? this.state.searchQuery[0].toUpperCase() + this.state.searchQuery.slice(1) : this.state.searchQuery.replace(/ /g, "_").replace("&", "")}&resultType=${this.state.searchType}`,
                                        state: { techAreaSelected: this.state.selectedTechArea }
                                    }}
                                />
                            )}

                            <div className="headerIcons">
                                {items.map((item, index) => (
                                    <div key={index}
                                        className={`headerIconItems ${this.state.activeItem === item.itemName ? 'active' : 'inactiveHover'
                                            }`}
                                        onClick={() => this.handleItemClick(item.itemName)}

                                    >
                                        <img src={item.imageUrl} alt={`Image ${index + 1}`} style={index !== 0 ? { width: '6.5rem' } : { width: '5rem' }} />
                                        <h6>{item.itemName}</h6>
                                        {this.state.activeItem === item.itemName && (
                                            <i className="fas fa-caret-up" />
                                        )}
                                    </div>
                                ))}

                            </div>
                            <div className="home__jumbotron__search__wrapper" id="search-suggestions-box" >
                                <form className="home__jumbotron__search__form" onSubmit={this.sendSearchQuery} tabIndex="-1" onFocus={this.showSearchSuggestions}
                                    // onBlur={this.hideSearchSuggestions}
                                    style={{ marginTop: '-4px' }}
                                    autoComplete="off"
                                >

                                    <span className="searchFilter" onClick={handleTechAreaSelect} ><TechAreasDrawer
                                        updatePadding={this.updateInputPadding}
                                        onSelectTechArea={this.handleTechAreaSelect}

                                    /></span>
                                    <input
                                        type="text"
                                        name="searchQuery"
                                        className="home__jumbotron__search searchPlaceholder"
                                        placeholder={placeholder}
                                        value={this.state.searchQuery}
                                        onChange={this.onChangeQuery}
                                        onKeyDown={this.searchKeyDownHandler}
                                        style={this.state.inputPadding}
                                    />

                                    {this.state.searchQuery !== "" && (
                                        <div
                                            className="search__query-clear-btn search__query-clear-btn--home"
                                            onClick={this.clearSearchQuery}
                                        >
                                            <svg className='home-search__clear-icon'>
                                                <use href={"https://tcr-documents.s3.ap-south-1.amazonaws.com/home_screen/sprite.svg" + "#clear"} />
                                            </svg>
                                        </div>
                                    )}
                                    <button type="submit" className="home__jumbotron-search-btn newButtonExtra">
                                        <i className="fas fa-search fa-lg" style={{ color: "#ffffff" }} />
                                    </button>
                                </form>

                                {/* <div className="home__jumbotron__search__text__wrapper" onClick={ ()=> this.props.history.push('/ai_search')}>
                                    <img src={right} className='home__jumbotron__search__text__img'/>
                                    <p className="home__jumbotron__search__text">Try our new AI-powered search</p>
                                    <button className="home__jumbotron__search__btn">Beta</button>
                                </div> */}

                                {(this.state.suggestedEntities.length > 0 ||
                                    this.state.suggestedLocations.length > 0 ||
                                    this.state.suggestedTechAreas.length > 0 ||
                                    this.state.suggestedProducts.length > 0) && (
                                        <div
                                            className={`${this.state.showSuggestions
                                                ? "home__search-suggestions--open"
                                                : "home__search-suggestions"
                                                }`}
                                        >
                                            {searchSuggestions}
                                        </div>
                                    )}
                            </div>



                            {/* {Object.keys(this.state.entitySummaryData).length ?
                            <div className="home__summaryGlance">
                                {[
                                    { 'url': 'Companies', 'search_name': 'companies', 'short_name': 'company', 'name': 'Companies', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/companies.svg' },
                                    { 'url': 'Academia', 'search_name': 'academia', 'short_name': 'academia', 'name': 'Academia', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/academia.svg' },
                                    { 'url': 'R_and_D_Centers', 'search_name': 'r_and_d', 'short_name': 'r_and_d', 'name': 'R&D Centers', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/r-d-center.svg' },
                                    { 'url': 'Researchers', 'search_name': 'individual', 'short_name': 'individuals', 'name': 'Researchers', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/researchers.svg' },
                                    { 'url': 'Unique_products', 'search_name': 'products', 'short_name': 'products', 'name': 'Unique products', 'imgLink': 'https://tcr-documents.s3.ap-south-1.amazonaws.com/svg/unique-products.svg' }].map((item, i) => {
                                        return <SummaryGlance
                                            key={i}
                                            name={item['name']}
                                            logo={item['imgLink']}
                                            search={item['search_name']}
                                            url={item["url"]}
                                            data={this.state.entitySummaryData[item.short_name]} />
                                    })}
                            </div>
                            : ''} */}


                            <h3 className='home__jumbotron__search__form' style={{ marginTop: '5px', color: '#ffffff', height: '4.5rem', justifyContent: 'flex-end'}}>
                                Try our new 
                                <a href="ai_search" style={{ color: '#ffffff', textDecoration: 'underline', marginLeft: '5px', marginRight: '5px' }}>AI-powered search</a>
                                <span style={{ background: "#F0E68C", color: 'black', borderRadius: '3px', paddingLeft: '5px', paddingRight: '5px' }}>BETA</span>
                            </h3>
                            <div className="containerSubHeading">
                                <h6>Discover Start-ups, Companies, R&D Labs, Academia and Individuals</h6>
                                <h6>Building India's Emerging Tech Capabilities</h6>
                            </div>

                        </div>

                    </div>


                </div>
            </>
        );
    }
}

const mapStateToProps = state => ({
    userInfo: state.auth.userInfo
});

const mapDispatchToProps = dispatch => ({
    setUserInfo: userInfo => dispatch(setUserInfo({ userInfo: userInfo })),
    setFormType: formType => dispatch(setFormType({ formType: formType })),
    updateArea: area => dispatch(updateArea({ area: area })),
    updateCapability: capability =>
        dispatch(updateCapability({ capability: capability })),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeTop));