import React, { useState, useEffect } from 'react';
import EntityLookupSuggestions from '../../components/claim-profile/EntityLookupSuggestions';
import api from '../../api';
import Claimprofilecard from './Claimprofilecard';
import Slider from "react-slick";
import { LiaAngleLeftSolid, LiaAngleRightSolid } from "react-icons/lia";

const ClaimProfile = (props) => {
  const { setClaimStatus, setOrganizationName, setCompanyUrl } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [lookupResults, setLookupResults] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [hoveredCard, setHoveredCard] = useState(false);

  // const claimProfileText = [
  //   `Why Claim Your Profile?<br/>
  //   <div style="margin-bottom: 10px;"></div>
  //   • <b>Enhance Visibility: </b>Ensure your company stands out in India's premier technology ecosystem.<br/>
  //   • <b>Showcase Expertise: </b>Demonstrate your company's unique strengths and capabilities.<br/>
  //   • <b>Build Connections: </b>Network with industry leaders and innovators.`,

  //   `Steps to Claim Your Profile:<br/>
  //   <div style="margin-bottom: 10px;"></div>
  //   • <b>Search for your company name on TechSagar.</b><br/> 
  //   • <b>Enter your official company email and verify it using the OTP.</b><br/> 
  //   • <b>Provide your name and designation.</b><br/> 
  //   • <b>Confirm your request to claim the profile.</b><br/> 
  //   • <b>After submitting your request, the TechSagar team will verify the information and notify you via email with the confirmation.</b><br/>`,

  //   // "Don’t miss this opportunity to boost your company’s visibility and influence in the industry. Claim your profile today!"
  // ];


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    nextArrow: React.cloneElement(<LiaAngleRightSolid size={40} color={hoveredCard ? "#2e5081" : "white"} />, {
      className: 'slick-next',
      onMouseEnter: () => setHoveredCard(true),
      onMouseLeave: () => setHoveredCard(false),
    }),
    prevArrow: React.cloneElement(<LiaAngleLeftSolid size={40} color={hoveredCard ? "#2e5081" : "white"} />, {
      className: 'slick-prev',
      onMouseEnter: () => setHoveredCard(true),
      onMouseLeave: () => setHoveredCard(false),
    }),
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 2 } },
      { breakpoint: 992, settings: { slidesToShow: 1 } }
    ]
  };


  useEffect(() => {
    const handleClickOutside = (event) => {
      let suggestionsBox = document.getElementById("feature-form__suggestions");
      if (suggestionsBox && !suggestionsBox.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleQueryChange = (event) => {
    setSearchQuery(event.target.value);
    // dLookupEntity();
  };

  useEffect(() => {
    dLookupEntity();
  }, [searchQuery])

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const lookupEntity = () => {
    console.log(searchQuery)
    if (searchQuery === '') {
      setLookupResults([]);
      setShowSuggestions(false);
      return;
    }

    if (searchQuery.replace(/\s+/, "").length > 1) {
      const requestConfig = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: searchQuery })
      };

      fetch(api.entityLookup, requestConfig)
        .then(response => {
          if (!response || !response.ok) throw new Error(response.status);
          return response.json();
        })
        .then(response => {
          const relevantLookupData = response.data
            .map(entity => ({
              name: entity.name,
              id: entity.id,
              type: entity.type,
              city: entity.address.city,
              claimed: entity.claimed ? entity.claimed : false,
              url: entity.url // Include the URL here
            }))
            .filter(item => {
              if (item.type !== 'Individual Researcher' && item.type !== 'Academia') {
                return item
              }
            }
            );

          setLookupResults(relevantLookupData);
        })
        .catch(err => console.log('Could not lookup entity', err))
        .finally(() => setShowSuggestions(true));
    }
  };

  console.log(lookupResults)

  const debounced = (delay, fn) => {
    let timerId;
    return function (...args) {
      if (timerId) clearTimeout(timerId);

      timerId = setTimeout(() => {
        fn(...args);
        timerId = null;
      }, delay);
    };
  };

  const dLookupEntity = debounced(400, lookupEntity);

  const title = 'Search & Claim your Entity';
  const placeholder = 'Eg. Infosys Ltd.';

  const switchToClaimEntity = (entity) => {
    // props.switchToClaimEntity(entity);
    setOrganizationName(entity.name);
    console.log(entity)
    setClaimStatus(3)
    props.setClaimStatus(2)
    setCompanyUrl(entity.url); // Set the company URL
    // setClaimStatus(2)
  };

  const suggestions = lookupResults.length ?
    lookupResults.map(suggestion => (
      <EntityLookupSuggestions
        key={suggestion.id}
        item={suggestion}
        switchToClaimEntity={switchToClaimEntity}
      />
    )) : (
      <div className='featureForm__emptySuggestions'>
        <p className='featureForm__emptySuggestionsText'>
          No Matching Results Found...
        </p>
      </div>
    );

  return (
    <div className='claimprofile__container'>
      {/* <h1>Add / Search Company</h1> */}
      <div className="claimprofile__search__container">
        <p className="feature_form__head">{title}</p>
        <div className='feature_form_head_elem'>
          <span style={{fontSize: '2rem', fontStyle: 'italic'}}>Claim Your Company Profile on TechSagar – India’s Largest Emerging Technology Capabilities Ecosystem!</span>
          <span style={{fontSize: '1.5rem'}}>Boost your company’s visibility on TechSagar. Join innovators, showcase your expertise, and connect with industry leaders. Claim your profile now!</span></div>

        <div style={{ marginTop: '2rem' }} className="feature_form__inner-wrapper">
          <form
            autoComplete='off'
            onSubmit={handleSubmit}
            className='feature-form__entity-lookup-form'
          >
            <label className='feature-form__search-entity-label'>Search</label>
            <input
              type='text'
              className='feature-form__search-entity-input feature-form__search-entity-style'
              style={{boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgb(209, 213, 219) 0px 0px 0px 1px inset'}}
              placeholder={placeholder}
              onChange={handleQueryChange}
              value={searchQuery}
            />
            {showSuggestions && (
              <div
                id='feature-form__suggestions'
                // className='feature-form__search-entity-suggestions'
                className='claimprofile-form__search-entity-suggestions'
              >
                {suggestions}
              </div>
            )}
          </form>
        </div>

        <div className='claimprofile__card__parent'>

          <div className='claimprofile_card_ai'>
            <div className='claimprofile__card__text'>
              <span style={{display: 'flex', justifyContent: 'center', marginBottom: '20px', fontWeight: 'bold'}}>Why Claim Your Profile?</span>
              <span style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
                <span><b>• Enhance Visibility:</b> Ensure your company stands out in India’s premier technology ecosystem.<br /></span>
                <span><b>• Showcase Expertise:</b> Demonstrate your company’s unique strengths and capabilities.<br /></span>
                <span><b>• Build Connections:</b> Network with industry leaders and innovators.</span>
              </span>
            </div>
          </div>

          <div className='claimprofile_card_ai'>
            <div className='claimprofile__card__text'>
              <span style={{display: 'flex', justifyContent: 'center', marginBottom: '20px', fontWeight: 'bold'}}>Steps to claim your profile</span>
              <span style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
              <span>• Search for your company name on TechSagar.<br /></span>
              <span>• Enter your official company email and verify it using the OTP.<br /></span>
              <span>• Submit your name and designation.<br /></span>
              <span>• Confirm your request to claim the profile.<br /></span>
              <span>• TechSagar team will verify and notify you via email.</span>
              </span>
            </div>
          </div>

        </div>
        {/* <Slider {...settings}>
          {claimProfileText.map((text, i) => (
            <div key={i} className="claimprofile__card__parent">
              <Claimprofilecard text={text}></Claimprofilecard>
            </div>
          ))}
        </Slider> */}
        {/* <p style={{ fontSize: '1.4rem' }}>OR</p> */}
        {/* <div className="feature_form__btn-wrapper">
            <button
              onClick={props.switchToAddEntity}
              className="feature_form__btn"
            >Add {title}</button>
          </div> */}
      </div>
    </div>
  );
};

export default ClaimProfile;
