import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import profile from '.././img/user.png';

import FollowingComponent from './dashboard-components/FollowingComponent';
import Contributor from './dashboard-components/Contributor';
import Followers from './dashboard-components/Followers';
import Feed from './dashboard-components/Feed';
import ClaimedEntity from './claim-profile/claimedentity';
import businessIcon from '../img/business-type-light.svg';
import Loader from './ui/loader/Loader';
import api from '../api';


class Dashboard extends React.Component {
  state = {
    isLoadingData: false,
    currentMenuItem: "feed",
    followedCompanies: [],
    followedTechAreas: [],
    isCompanyListExpanded: false,
    isTechnologyListExpanded: false,
    currentCredits: 0,
    expirationDate: '',
    contributor: [],
    followers:[],
    latestFeed: []
  };

  componentDidMount() {
    //  retrieve URL params 
    const urlParams = new URLSearchParams(this.props.location.search);

    //  In case we are redirected here post-payment, there will be a status param
    //  Use it to determine transaction status and display a corresponding toast
    const status = urlParams.get('status');//TO Do - need to do
    
    if (status !== null) {
      if (status === 'True') toast.success('Credits have been successfully credited to your account');
      else if (status === 'False') toast.error('The transaction has failed. Please try again later');
    }

    //  Get the query param section (which is used to directly open notifications tab)

    // const section = urlParams.get('section');//OLD 
    // NEW
    let section = this.props.location.pathname.split('/')
    section = section[section.length - 1]

    if (section !== null) {
      if (['following', 'activities'].includes(section)) {
        this.setState({ currentMenuItem: section });
      }
    }

    //  Happens regardless
    this.fetchFollowedEntities();
    this.getCurrentCredits();
    this.getContributor();
    this.getFollowers();
    this.getLatestFeed();
  }

  generateDateString = dateTimeStamp => {
    const date = new Date(dateTimeStamp);
    const day = date.getDate();
    const year = date.getFullYear();
    let month = date.getMonth();

    if (month === 0) month = 'January';
    else if (month === 1) month = 'February';
    else if (month === 2) month = 'March';
    else if (month === 3) month = 'April';
    else if (month === 4) month = 'May';
    else if (month === 5) month = 'June';
    else if (month === 6) month = 'July';
    else if (month === 7) month = 'August';
    else if (month === 8) month = 'September';
    else if (month === 9) month = 'October';
    else if (month === 10) month = 'November';
    else if (month === 11) month = 'December';

    return `Till ${month} ${day}, ${year}`;
  }

  getCurrentCredits = () => {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.userInfo.token
      }
    };

    fetch(api.getCredits, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch current credits', response.status);
        return;
      }
      response.json().then(response => {
        
        this.setState({
          currentCredits: response.data.credits_remaining,
          expirationDate: this.generateDateString(response.data.expire_date),
         
        });
      })
    }).catch(err => {
      console.log('Error fetching current credits', err);
  
    });
  }
  getLatestFeed = () => {
    this.setState({ isLoadingData: true });
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.userInfo.token
      }
    };

    fetch(api.latestFeed, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch current credits', response.status);
        return;
      }
      response.json().then(response => {
         
        this.setState({
          latestFeed : response.data,
          isLoadingData: false
        });
      })
    }).catch(err => {
      console.log('Error fetching current credits', err);
      this.setState({ isLoadingData: false });
    });
  }
  getContributor = () => {
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.userInfo.token
      }
    };

    fetch(`${api.contributorDetails}?email=${this.props.userInfo.email}`, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch current credits', response.status);
        return;
      }
      response.json().then(response => {
        
        this.setState({
          contributor : response.data,
          
        });
      })
    }).catch(err => {
      console.log('Error fetching current credits', err);
      
    });
  }

  getFollowers = () => {
     
    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.userInfo.token
      }
    };

    fetch(api.followers, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch current credits', response.status);
        return;
      }
      response.json().then(response => {
       
        this.setState({
          followers : response.data,
        
        });
      })
    }).catch(err => {
      console.log('Error fetching current credits', err);
     
    });
  }
  unfollowCompany = companyId => event => {
    event.preventDefault();

    const requestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        TCRAUTH: this.props.userInfo.token
      },
      body: JSON.stringify({
        type: 'like',
        form_id: companyId
      })
    };

    fetch(api.consumer_action_on_entity, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not unfollow company, status code', response.status);
        return;
      }

      //  Proceed for succcessful request
      this.fetchFollowedEntities();
      
    }).catch(err => console.log('Error unfollowing company', err));
  }

  unfollowTechnology = area => event => {
    event.preventDefault();
    const requestConfig = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        TCRAUTH: this.props.userInfo.token
      },
      body: JSON.stringify({
        action: 'like',
        technology: area
      })
    };

    fetch(api.action_on_technology, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not unfollow tech area, status code', response.status);
        return;
      }

      //  Proceed for successful request
      this.fetchFollowedEntities();
      
    })
  }

  fetchFollowedEntities = () => {
   

    const requestConfig = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'TCRAUTH': this.props.userInfo.token
      }
    };

    fetch(api.getFollowedEntities, requestConfig).then(response => {
      if (!response || !response.ok) {
        console.log('Could not fetch followed entities, status code', response.status);
        return;
      }

      //  Proceed for successful request
      response.json().then(response => {
        
        const followedCompanies = response.data.following_companies_data.map(comp => ({
          name: comp.name,
          location: comp.city,
          id: comp.id,
          type:comp.type,
          route:comp.route
        }));
        const followedTechAreas = response.data.following_tech_areas_data.map(area => ({
          name: area._id
        }));

        this.setState({
          followedCompanies: followedCompanies,
          followedTechAreas: followedTechAreas,
         
        });
      });
       
    }).catch(err => {
      console.log('Error occurred while fetching entities', err);
      this.setState({ isLoadingData: false });
    });
  }

  changeCurrentMenuItem = item => this.setState({
    currentMenuItem: item
  });

  toggleListExpansion = listName => event => {
    this.setState(prevState => {
      if (listName === 'company') {
        return { isCompanyListExpanded: !prevState.isCompanyListExpanded };
      } else if (listName === 'technology') {
        return { isTechnologyListExpanded: !prevState.isTechnologyListExpanded };
      } else return prevState;
    });
  }


  render() {

    document.title = "Dashboard - Technology Capability Repository || DSCI";
    const { userInfo } = this.props;
    const redirect = !userInfo || !userInfo.token ?
      <Redirect to='/' /> : null;

    const companies = this.state.isCompanyListExpanded ?
      this.state.followedCompanies :
      this.state.followedCompanies.slice(0, 4);
    const techAreas = this.state.isTechnologyListExpanded ?
      this.state.followedTechAreas :
      this.state.followedTechAreas.slice(0, 4);

    let dashboardComponent = null;
    if (this.state.currentMenuItem === 'following') {
      dashboardComponent = (
        <FollowingComponent
          unfollowCompany={this.unfollowCompany}
          unfollowTechnology={this.unfollowTechnology}
          companiesToShow={companies}
          techAreasToShow={techAreas}
          totalCompanies={this.state.followedCompanies.length}
          totalTechAreas={this.state.followedTechAreas.length}
          showCompListToggle={this.state.followedCompanies.length > 4}
          showTechListToggle={this.state.followedTechAreas.length > 4}
          toggleList={this.toggleListExpansion}
          currentCredits={this.state.currentCredits}
          expirationDate={this.state.expirationDate}
        />
      );
    } else if (this.state.currentMenuItem === 'contributor') {
      const contributor = this.state.contributor && this.state.contributor.length > 0
      dashboardComponent = (
        <Contributor
          contributorDetails={this.state.contributor}
          caseStudy = {this.state.contributor['case_study']}
          reports = {this.state.contributor['reports']}
          blogs = {this.state.contributor['blogs']}
          
        />
      );
    } else if (this.state.currentMenuItem === 'followers') {
      dashboardComponent = (
        <Followers  
        followers={this.state.followers} />
      );
    }
    else if (this.state.currentMenuItem === 'feed') {
      dashboardComponent = (
        <Feed
        latestFeed ={this.state.latestFeed} />
      );
    }
    else if (this.state.currentMenuItem === 'claimed') {
      dashboardComponent = (
        <ClaimedEntity
        authToken={this.props.userInfo.token} />
      );
    }

    return (
      <div>
        <Loader loading={this.state.isLoadingData} height="400px">
          {redirect}

          <div className="entityview__top">
            <div className="entityview__top-desc-wrapper">
              <div className="entityview__top-breadcrumb"
              >Account / Dashboard</div>
              <div>
              <div className='dashboard__profile_container'>
              <img src={profile} className='dashboard__profile' alt="profile"/>
              <div className='dashboard__profile_detail'>
                <p className="entityview__top-head">{userInfo.first_name} {userInfo.last_name}</p>
                <div className="entityview__subhead">
                  <div className="entityview__subhead-section entityview__stats-section">
                    {/* // TODO -- add checks for designation and then only render  */}
                    {userInfo.designation && <div className="entityview__subhead-desc">
                     {/* <img src={businessIcon} alt='business icon' className='entityview__subhead-desc-icon' />*/}
                     {userInfo.email}<br/>
                      {userInfo.designation}<br/>
                      {userInfo.company_name}
                    </div>}

                  </div>
                </div>
                </div>
              </div>
              <div  className='dashboard_container'>
                <button 
                   onClick={() => {
                    
                    this.props.history.push('/my_account');
                  }}
                
                 className='dashboard__btn'>EDIT</button>
              </div>
              </div>
            </div>

             </div>
             
          {/* End of entityview__top */}
          <div className="entityview__top-nav">
              <div
                onClick={this.changeCurrentMenuItem.bind(null, "feed")}
                className={`entityview__top-nav-btn ${this.state.currentMenuItem ===
                  "feed" && "entityview__top-nav-btn--active"}`}
              >FEED</div>
                <div
                onClick={this.changeCurrentMenuItem.bind(null, "followers")}
                className={`entityview__top-nav-btn ${this.state.currentMenuItem ===
                  "followers" && "entityview__top-nav-btn--active"}`}
              >FOLLOWERS ({this.state.followers.length})</div>

              <div
                onClick={this.changeCurrentMenuItem.bind(null, "following")}
                className={`entityview__top-nav-btn ${this.state.currentMenuItem ===
                  "following" && "entityview__top-nav-btn--active"}`}
              >FOLLOWING ({this.state.followedCompanies.length + this.state.followedTechAreas.length})</div>

             
              <div
                onClick={this.changeCurrentMenuItem.bind(null, "contributor")}
                className={`entityview__top-nav-btn ${this.state.currentMenuItem ===
                  "contributor" && "entityview__top-nav-btn--active"}`}
              >CONTRIBUTION</div>

              <div
                onClick={this.changeCurrentMenuItem.bind(null, "claimed")}
                className={`entityview__top-nav-btn ${this.state.currentMenuItem ===
                  "claimed" && "entityview__top-nav-btn--active"}`}
                >CLAIMED ENTITY
                </div>


              {/*
           <div
                onClick={this.changeCurrentMenuItem.bind(null, "activities")}
                className={`entityview__top-nav-btn ${this.state.currentMenuItem ===
                  "activities" && "entityview__top-nav-btn--active"}`}
                >ACTIVITIES</div> 

              <div
                onClick={this.changeCurrentMenuItem.bind(null, "transactions")}
                className={`entityview__top-nav-btn ${this.state.currentMenuItem ===
                  "transactions" && "entityview__top-nav-btn--active"}`}
                >TRANSACTIONS</div>*/}
            </div>
          
          {dashboardComponent}
          
        </Loader>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  userInfo: state.auth.userInfo
});

Dashboard = connect(mapStateToProps, null)(Dashboard);

export default withRouter(Dashboard);