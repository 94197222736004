import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import api from '../../api';
import supportBg from "../../img/support-bg.svg";

const SupportAndFeedback = ({ closeModal }) => {
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [submitResponse, setSubmitResponse] = useState(null);

  const formData = {
    fields: {
      type: {
        '#type': 'radios',
        '#title': 'Type',
        '#options': {
          support: 'Support',
          bug: 'Bug',
          improvement: 'Improvement',
        },
        '#required': true,
        '#required_error': 'Please select the request type',
      },
      name: {
        '#type': 'textfield',
        '#title': 'Name',
        '#placeholder': 'Please enter your name.',
        '#required': true,
        '#required_error': 'Name field is required',
      },
      email: {
        '#type': 'email',
        '#title': 'Email',
        '#placeholder': 'Please enter your email.',
        '#required': true,
        '#required_error': 'Email field is required',
      },
      message: {
        '#type': 'textarea',
        '#title': 'Message',
        '#placeholder': 'Write your valuable message here.',
        '#required': true,
        '#required_error': 'Please enter the message in minimum 200 characters.',
      },
    },
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const validateForm = () => {
    let valid = true;
    let errors = {};

    Object.entries(formData.fields).forEach(([fieldName, fieldConfig]) => {
      if (fieldConfig['#required'] && !formValues[fieldName]) {
        valid = false;
        errors[fieldName] = fieldConfig['#required_error'];
      }
      if (fieldConfig['#type'] === 'textarea' && (!formValues[fieldName] || formValues[fieldName].length < 200)) {
        valid = false;
        errors[fieldName] = 'Please enter the message in minimum 200 characters.';
      }
    });

    setErrors(errors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }

    try {
      const csrfResponse = await fetch(`${process.env.REACT_APP_API_URL_CMS}/session/token`);
      if (csrfResponse.status !== 200) {
        throw new Error('Failed to fetch CSRF token');
      }
      const csrfToken = await csrfResponse.text();

      const payload = {
        webform_id: 'support_feedback',
        ...formValues,
      };

      const response = await fetch(api.submit_webform_detail, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Auth-Key': process.env.REACT_APP_AUTH_KEY,
          'X-CSRF-Token': csrfToken,
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (data.status === 200) {
        setSubmitResponse({
          success: true,
          message: 'Form submitted successfully!',
        });
        toast.success('Form submitted successfully!');
        closeModal();
      } else {
        setSubmitResponse({
          success: false,
          message: 'An error occurred while submitting the form.',
        });
        toast.error('An error occurred while submitting the form.');
      }
    } catch (error) {
      setSubmitResponse({
        success: false,
        message: 'An error occurred while submitting the form.',
      });
      toast.error('An error occurred while submitting the form.');
    }
  };

  const renderField = (fieldName, fieldConfig) => {
    const commonProps = {
      name: fieldName,
      value: formValues[fieldName] || '',
      onChange: handleChange,
    };

    switch (fieldConfig['#type']) {
      case 'radios':
        return (
          <div className="row user__form-row feedbackType-wrapper" key={fieldName}>
            <label style={{paddingLeft: '0'}} className='form__label'>{fieldConfig['#title']}</label>
            {Object.entries(fieldConfig['#options']).map(([value, label]) => (
              <div key={value}>
                <input
                  type="radio"
                  id={`${fieldName}_${value}`}
                  name={fieldName}
                  value={value}
                  checked={formValues[fieldName] === value}
                  onChange={handleChange}
                  className="feedbackType-radio"
                />
                <label htmlFor={`${fieldName}_${value}`} className="feedbackType-text">{label}</label>
              </div>
            ))}
            {errors[fieldName] && <span>{errors[fieldName]}</span>}
          </div>
        );
      case 'textfield':
        return (
          <div className="row user__form-row" key={fieldName}>
            <label style={{paddingLeft: '0'}} className='form__label' htmlFor={fieldName}>{fieldConfig['#title']}</label>
            <input
              type="text"
              id={fieldName}
              placeholder={fieldConfig['#placeholder']}
              {...commonProps}
              className='form__input-field'
              style={{marginBottom: '0.8rem'}}
            />
            {errors[fieldName] && <span>{errors[fieldName]}</span>}
          </div>
        );
      case 'email':
        return (
          <div className="row user__form-row" key={fieldName}>
            <label style={{paddingLeft: '0'}} className='form__label' htmlFor={fieldName}>{fieldConfig['#title']}</label>
            <input
              type="email"
              id={fieldName}
              placeholder={fieldConfig['#placeholder']}
              {...commonProps}
              className='form__input-field'
              style={{marginBottom: '0.8rem'}}
            />
            {errors[fieldName] && <span>{errors[fieldName]}</span>}
          </div>
        );
      case 'textarea':
        return (
          <div className="row user__form-row" key={fieldName}>
            <label style={{paddingLeft: '0'}} className='form__label' htmlFor={fieldName}>{fieldConfig['#title']}</label>
            <textarea
              id={fieldName}
              placeholder={fieldConfig['#placeholder']}
              {...commonProps}
              className="supportFeedbackDescription"
            />
            {errors[fieldName] && <span>{errors[fieldName]}</span>}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="user-form">
      <ToastContainer />
      <div className="user-form__left">
        <div className="user-form__left--text-wrapper">
          <div className="close-modal--mobileOnly">
            <i className="fa fa-caret-left" onClick={closeModal} aria-hidden="true" />
          </div>
          <div className="user-form__left-head">
            Give us your feedback
          </div>
          <div className="user-form__left-desc">
            We are here to help you
          </div>
        </div>
        <div className="user-form__left-img-wrapper">
          <img className="user-form__left-img" src={supportBg} alt="Lock background" />
        </div>
      </div>

      <div className="user-form__right">
        <div className="user-form__right-head">
          Support & Feedback
        </div>
        <form onSubmit={handleSubmit}>
          <div className="user__form-container">
            {Object.entries(formData.fields).map(([fieldName, fieldConfig]) => (
              renderField(fieldName, fieldConfig)
            ))}
            <div className="feedback-submit-btn" style={{ marginTop: "15px" }}>
              <button type="submit" className="form-button user-form__button">Submit</button>
            </div>
            {submitResponse && (
              <div className={`form-response ${submitResponse.success ? 'success' : 'error'}`}>
                {submitResponse.message}
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default SupportAndFeedback;
