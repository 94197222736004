import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../css/UserForm.css";
import "../../css/Form.css";
import loginBg from "../../img/login-bg.svg";
import api from "../../api"

function AISearchVerify({ isOpen, onClose, ragToken, setIsVerifiedModal, setPromptLimit }) {
  const [localEmail, setLocalEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [loadingVerify, setLoadingVerify] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [otpError, setOtpError] = useState('');
  const [otpGenerated, setOtpGenerated] = useState(false);
  const [timer, setTimer] = useState(0);

  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
    } else if (timer === 0) {
      // Allow the user to regenerate OTP
      setOtpGenerated(false);
    }
    return () => clearInterval(interval);
  }, [timer]);

  const handleGenerateOtp = async () => {
    if (!localEmail) {
      setEmailError('Email is required');
      return;
    }

    setLoadingOtp(true);
    try {
      const response = await fetch(api.AI_SEND_OTP, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'rag-token': ragToken,
        },
        body: JSON.stringify({ email: localEmail }),
      });

      const data = await response.json();

      if (response.ok) {
        setOtpGenerated(true);
        toast.success("OTP generated successfully");
        setTimer(30);  // Start the 30-second timer
      } else {
        setEmailError(data.message || 'Failed to send OTP');
      }
    } catch (error) {
      setEmailError('Failed to send OTP');
    }
    setLoadingOtp(false);
  };

  const handleVerifyOtp = async () => {
    if (!otp) {
      setOtpError('OTP is required');
      return;
    }

    setLoadingVerify(true);
    try {
      const response = await fetch(api.AI_VERIFY_OTP, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'rag-token': ragToken,
        },
        body: JSON.stringify({ otp, email: localEmail }),
      });

      const data = await response.json();

      if (response.ok && data.status === 200) {
        setPromptLimit(data.data.promptLimit);  // Pass the promptLimit value
        setIsVerifiedModal(true);
        onClose();
        toast.success("OTP verified successfully");
      } else if (data.status === 400) {
        setOtpError(data.message || 'Failed to verify OTP');
        toast.error(data.message || 'Failed to verify OTP');
      } else {
        setOtpError('Failed to verify OTP');
        toast.error('Failed to verify OTP');
      }
    } catch (error) {
      setOtpError('Failed to verify OTP');
      toast.error('Failed to verify OTP');
    }
    setLoadingVerify(false);
  };

  const handleEmailChange = (e) => {
    setLocalEmail(e.target.value);
    if (emailError) {
      setEmailError('');
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    if (otpError) {
      setOtpError('');
    }
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-left">
          <span>AI Search Email & OTP Verification</span>
          <span>Welcome! Please enter your details to continue.</span>
        </div>

        {/* Right side of the form */}
        <div className="modal-right">
          {/* Close icon */}
          <i className="fa fa-times close-button" onClick={onClose} aria-hidden="true" />

          <h2>Please verify your email address to continue the AI powered technology capabilities search</h2>
          {/* <p className="user-form__right-head" style={{ fontWeight: '600', fontSize: '2.2rem' }}>Please verify your email address to continue the AI powered technology capabilities search</p>
          <div className="user__form-container" style={{ display: 'flex', flexDirection: 'column', gap: '20px', marginTop: '25px' }}>
            <div className="user__form-row" style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'flex-start' }}> */}
             <div className="form-row">
              <label>Email:</label>
              <div className="input-group">
              {/* <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}> */}
                <input
                  className="input-field"
                  type="email"
                  value={localEmail}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  disabled={loadingOtp || loadingVerify}
                />
                <button
                  className="feature_form__btn"
                  onClick={handleGenerateOtp}
                  disabled={loadingOtp || loadingVerify || otpGenerated || timer > 0}
                  style={{ height: '4rem', minWidth: '17.2rem' }}
                >
                  {loadingOtp ? 'Sending...' : timer > 0 ? `Wait ${timer}s` : 'Generate OTP'}
                </button>
              </div>
              {emailError && <span style={{ color: '#e05151' }} className="error-message">{emailError}</span>}
            </div>

            <div className="form-row">

            {/* <div className="user__form-row" style={{ display: 'flex', flexDirection: 'column', gap: '16px', alignItems: 'flex-start' }}> */}
              <label>OTP:</label>
              <div className="input-group">
                <input
                  className="input-field"
                  type="text"
                  value={otp}
                  onChange={handleOtpChange}
                  placeholder="Enter OTP"
                  disabled={loadingOtp || loadingVerify}
                />
                <button
                  className="feature_form__btn"
                  onClick={handleVerifyOtp}
                  disabled={loadingOtp || loadingVerify}
                  style={{ height: '4rem', minWidth: '17.2rem' }}
                >
                  {loadingVerify ? 'Verifying...' : 'Verify'}
                </button>
              </div>
              {otpError && <span style={{ color: '#e05151' }} className="error-message">{otpError}</span>}
            </div>
          </div>
        </div>
      </div>
  );
}

export default AISearchVerify;
